import React from 'react';
import {Grid, Header} from 'semantic-ui-react';

function ComingSoon() {

    return (

        <Grid relaxed padded textAlign="center" verticalAlign="center" stretched style={{ height: "100%" }}>

            <Grid.Column width={16}>
                <Header as="h1" className="secondaryFont">Incoherents Club</Header>
            </Grid.Column>

            <Grid.Column width={16}>
                <Header as="h2">
                    Coming Soon!
                </Header>
                <Header sub>Stay Tuned</Header>
            </Grid.Column>

        </Grid>

    )

}

export default ComingSoon;